var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wet-insurance-card p-3",class:{
    'link': !!_vm.link,
    'm-0 m-md-3': !_vm.single,
    'single': _vm.single,
  },on:{"click":function($event){!!_vm.link && _vm.$router.push(_vm.link)}}},[(_vm.imageUrl && !_vm.single)?_c('div',{staticClass:"wet-insurance-card-business",style:({
      backgroundColor: _vm.color,
    })},[_c('img',{staticClass:"empty-img",attrs:{"src":_vm.imageUrl},on:{"load":function($event){return $event.target.classList.toggle('empty-img', false)}}})]):_vm._e(),(!_vm.single)?_c('div',{staticClass:"is-size-5 font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"d-flex mb-3"},[(!_vm.single)?_c('div',{staticClass:"text-secondary h-100 wet-icon-xl"},[_c('weather-icon',{staticClass:"wet-icon-large text-secondary mr-3",style:(_vm.baloiseStrings ? 'width:2rem;' : ''),attrs:{"type":_vm.type}})],1):_vm._e(),(_vm.imageUrl && _vm.single)?_c('div',{staticClass:"wet-insurance-card-business-single",style:({
        backgroundColor: _vm.color,
        padding: _vm.baloiseStrings ? 0 : '0.5rem',
      })},[_c('img',{staticClass:"empty-img",attrs:{"src":_vm.imageUrl},on:{"load":function($event){return $event.target.classList.toggle('empty-img', false)}}})]):_vm._e(),_c('div',{staticClass:"px-3 flex-grow-1 row no-gutters align-items-center",class:{
        'wet-append-chevron-right': !!_vm.link,
      }},[_c('div',{staticClass:"col mb-2 mb-sm-0"},[(_vm.single)?_c('div',{staticClass:"is-size-5"},[_vm._v(" "+_vm._s(_vm.businessTypeString)+" ")]):_vm._e(),(_vm.single)?_c('div',{staticClass:"text-secondary font-weight-bold"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.description)+" ")])]),(_vm.single && _vm.status === '2')?_c('div',{staticClass:"col-12 col-sm-auto"},[_c('button',{staticClass:"wet-button",class:{
            'loading': _vm.loading.length > 0
          },attrs:{"type":"button","disabled":_vm.loading.length > 0},on:{"click":function($event){return _vm.$emit('claim')}}},[_vm._v(" "+_vm._s(_vm.claim_payout_btn)+" ")])]):_vm._e()])]),_c('div',{},[_c('div',{staticClass:"wet-note"},[_vm._v(_vm._s(_vm.locations))]),_c('div',{staticClass:"wet-note"},[_vm._v(_vm._s([_vm.startDate, _vm.endDate].filter((a) => !!a).join(' / ')))]),_c('div',{staticClass:"wet-slash-split"},[(_vm.showPrices)?_c('div',[_c('div',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.price_including_vat)+" ")]),_c('div',{staticClass:"font-weight-bold"},[_c('money-field',{attrs:{"value":_vm.price,"size":"lg","currency":_vm.currency}})],1)]):_vm._e(),(_vm.showPayout)?_c('div',[_c('div',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.potential_payout)+" ")]),_c('div',{staticClass:"font-weight-bold"},[_c('money-field',{attrs:{"value":_vm.payout,"size":"lg","currency":_vm.currency}})],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }