import { hoursHumanize, periodHumanize } from '@/common/snippets';
import wpSection from '@/enums/wpSection';
import { nanoid } from 'nanoid';
import LogService from '@/services/LogService';
import { availableWeatherDirs } from '@/constants';
import { buy, commonApp, insurance } from '@/enums/stringIndices';

const insuranceId = {
  props: {
    initialBirthday: {
      default: '1980-01-01',
    },
  },
  computed: {
    lang() {
      return this.$route.params.lang ?? 'en';
    },
    insured_weather() {
      const iwParameter = this.str.commonApp?.weather_type[this.order.weather?.type];
      const iwOperator = [
        this.availableWeatherDirs.cold,
        this.availableWeatherDirs.nosnow,
        this.availableWeatherDirs.norain,
      ].includes(this.order.weather?.direction)
        ? this.str.commonApp?.less_than_or_equal
        : this.str.commonApp?.more_than;
      const iwUnit = this.str.commonApp?.unit[this.order.weather?.type];
      return this.format(
        this.str.buy?.insured_weather_subtitle,
        iwParameter,
        iwOperator,
        this.order.weather?.threshold,
        iwUnit,
      );
    },
    period() {
      return this.periodHumanize(this, this.order.dates);
    },
    periodHumanizeTest() {
      return this.periodHumanize(this, this.order.dates);
    },
    times() {
      return this.order.hours ? this.format(
        this.str.buy?.between_hours,
        this.hoursHumanize(this.order.hours),
      ) : '';
    },
    timesAdjacent() {
      return this.order.hours ? this.format(
        this.str.buy?.between_hours_adjacent,
        this.order.hours[0],
        this.order.hours[this.order.hours.length - 1] + 1,
      ) : '';
    },
    coordinates() {
      if (this.order.locations === 'undefined') {
        return '';
      }
      return this.order.locations.map((location) => `${this.str.buy?.gps_coordinates} \
          ${location?.latitude}, \
          ${location?.longitude}`);
    },
    insurance() {
      return this.mapApiData(this.order);
    },
    claims() {
      return this.mapApiClaims(this.order.claims_per_day || []);
    },
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      initialFirstname: '',
      initialLastname: '',
      additionalFirstnames: '',
      birthday: this.initialBirthday,
      birthPlace: '',
      payoutIban: '',
      apiPolicy: `/api/user/download/policy/${this.$route.params.id}`,
      availableWeatherDirs,
      str: {
        insurance,
        commonApp,
        buy,
      },
      showClaimForm: false,
    };
  },
  methods: {
    async init() {
      await Promise.all([
        this.getWordPressStrings([wpSection.BUY, wpSection.INSURANCE, wpSection.COMMON_APP]),
        this.$root.$on('buttonBack', this.back),
        this.getUserData(),
      ]);
    },
    async getUserData() {
      if (this.order?.status === 2) {
        const res = await this.$webAppAPI.getUserInfoUserOnly();
        this.initialFirstname = res?.data?.profile?.firstname || '';
        this.firstname = this.initialFirstname;
        this.initialLastname = res?.data?.profile?.lastname || '';
        this.lastname = this.initialLastname;
        LogService.log('user info', res.data, this.firstname, this.lastname);
      }
    },
    sendClaimClick() {
      if (!this.showClaimForm) {
        this.showClaimForm = true;
      } else {
        this.$refs.claimForm.$v.$touch();
        if (!this.$refs.claimForm.$v.$error) {
          const body = {
            order_id: this.$route.params.id,
            date_of_birth: this.birthday,
            place_of_birth: this.birthPlace,
            payout_iban: this.payoutIban,
            firstname: this.firstname,
            lastname: this.lastname,
          };
          if (this.additionalFirstnames) {
            body.additional_firstnames = this.additionalFirstnames;
          }
          this.postClaim(body).catch(() => {
            this.showModal('wet-reset-pwd-email-modal', this.str.insurance.error_send_claim);
          });
          this.showClaimForm = false;
          LogService.log('sendClaimClick, send claim');
        }
      }
    },
    async postClaim(body) {
      const id = nanoid();
      LogService.log('creating screen, loadingId', id);
      this.$store.commit('SET_LOADING', id);
      await this.$webAppAPI.postClaim(body);
      this.$store.commit('DEL_LOADING', id);
      this.$emit('getClaim');
      this.showModal('wet-reset-pwd-email-modal', this.str.insurance.claim_message);
    },
    showModal(id, message) {
      const modal = {
        id,
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-reset-pwd-email-modal-ok',
            title: this.str.commonApp?.ok,
            event: 'close',
          },
        ],
        close: this.onCloseModal,
      };
      this.$store.dispatch('addModal', modal);
    },
    mapApiClaims(claims_per_day) {
      /** map api data to claim object */
      return claims_per_day.map((c) => ({
        id: c.claim_id,
        date: c.date,
        type: this.order.weather?.type,
        claim: c.claim_amount,
        days: this.order.risk_days,
        threshold: c.value_known ? c.value : null,
        noClaim: c.threshold_met && c.non_insured_day,
        status: c.status,
        lastHour: c.last_hour,
        needs_to_be_checked: c.needs_to_be_checked,
      }));
    },
    back() {
      this.$router.push({ name: 'insurance', params: { ...this.$route.params } });
    },
    hoursHumanize,
    periodHumanize,
  },
};

export default insuranceId;
