/* PERIOD HUMANIZE */

function areDatesAdjacent(dateStrings) {
  for (let i = 0; i < dateStrings.length - 1; i += 1) {
    const currentDate = new Date(dateStrings[i]);
    const nextDate = new Date(dateStrings[i + 1]);

    // Check if the next date is one day after the current date
    if (nextDate.getTime() - currentDate.getTime() !== 24 * 60 * 60 * 1000) {
      return false; // Not adjacent
    }
  }

  return true; // All dates are adjacent
}
const periodHumanize = (that, dates) => {
  // dates array to formatted string
  const { lang } = that.$store.state;

  const areAdjacent = areDatesAdjacent(dates);
  if (areAdjacent) {
    let firstDate = new Date(dates[0]);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    firstDate = new Intl.DateTimeFormat(lang, options).format(firstDate);
    if (dates.length === 1) {
      return firstDate;
    }
    let lastDate = new Date(dates[dates.length - 1]);
    lastDate = new Intl.DateTimeFormat(lang, options).format(lastDate);
    return `${firstDate} - ${lastDate}`;
  }

  // sort
  const datesSorted = (dates || []).map((date) => that.$moment(date)).sort((a, b) => a - b);
  // group by Years and months, and split by intervals
  let prevDate = null;
  let interval = 0;
  const datesGrouped = datesSorted.reduce((acc, cur, i) => {
    const year = cur.year();
    const month = cur.month();
    if (i === 0 || !('length' in that.$lodash.get(acc, [year, month, interval], {}))) {
      that.$lodash.setWith(acc, [year, month, interval], [], Object);
    } else if (cur.diff(prevDate, 'days') > 1) {
      interval += 1;
      that.$lodash.setWith(acc, [year, month, interval], [], Object);
    }
    acc[year][month][interval].push(cur.date());
    prevDate = cur;
    return acc;
  }, {});
  // map by months, intervals, years
  const res = [];
  Object.entries(datesGrouped).forEach(([year, months]) => {
    const mInterval = [];
    Object.entries(months).forEach(([month, intervals]) => {
      let mIntervalEl = '';
      // push month name for EN
      if (lang === 'en') mIntervalEl += `${(that.$moment({ M: month }).format('MMM'))} `;

      const dInterval = [];
      const delimiter = lang === 'de' ? '.' : '';
      Object.values(intervals).forEach((iDates) => {
        if (iDates.length > 1) {
          dInterval.push([`${Math.min(...iDates)}${delimiter}`, `${Math.max(...iDates)}${delimiter}`].join('-'));
        } else dInterval.push(`${iDates[0]}${delimiter}`);
      });
      // push intrevals strings
      mIntervalEl += `${dInterval.join(', ')}`;

      // push month name for DE
      if (lang === 'de') mIntervalEl += ` ${(that.$moment({ M: month }).format('MMM'))}`;

      mInterval.push(mIntervalEl);
    });

    // push year
    res.push(`${mInterval.join(', ')} ${year}`);
  });
  return res.join('; ');
};
/* PERIOD HUMANIZE END */

const numToPriceFormat = (num, lang, split) => {
  /* format num or string '1000' to '1.000' or '1,000'
    * '1000.7' to '1000.70'
    * @num: String || Number
    * @lang: String
    * @split: Boolean
    * if has Split param return array of strings [ceil, centDelimiter, cent]
    */
  const delimiter = lang === 'de' ? '.' : ',';
  const centDelimiter = lang === 'de' ? ',' : '.';
  let n = '';
  let cent = `${num || 0}`.split('.')[1] || '';
  if (cent && cent.length < 2) cent += '0';
  const nn = `${num || 0}`.split('.')[0].split('').reverse();
  for (let index = nn.length - 1; index >= 0; index -= 1) {
    n += nn[index];
    if (!((index) % 3) && index !== 0) n += delimiter;
  }
  const result = split
    ? [n, centDelimiter, cent || '00']
    : n + (cent ? `${centDelimiter}${cent}` : '');
  return result;
};

const hoursHumanize = (hours) => {
  // hours array to string
  // sort
  const hoursSorted = [...(hours || [])];
  hoursSorted.sort((a, b) => a - b);
  // to string
  return `${hoursSorted[0]} - ${hoursSorted.pop() + 1}`;
};

// const uuu = () => {};

export {
  periodHumanize,
  numToPriceFormat,
  hoursHumanize,
};
