<template>
  <div
    class="wet-insurance-card p-3"
    :class="{
      'link': !!link,
      'm-0 m-md-3': !single,
      'single': single,
    }"
    @click="!!link && $router.push(link)"
  >
    <div
      v-if="imageUrl && !single"
      class="wet-insurance-card-business"
      :style="{
        backgroundColor: color,
      }"
    >
      <img
        :src="imageUrl"
        @load="$event.target.classList.toggle('empty-img', false)"
        class="empty-img"
      >
    </div>
    <div v-if="!single" class="is-size-5 font-weight-bold mb-3">{{title}}</div>
    <div class="d-flex mb-3">
      <div v-if="!single" class="text-secondary h-100 wet-icon-xl">
        <weather-icon
          class="wet-icon-large text-secondary mr-3"
          :type=type
          :style="baloiseStrings ? 'width:2rem;' : ''"
        ></weather-icon>
      </div>
      <div
        v-if="imageUrl && single"
        class="wet-insurance-card-business-single"
        :style="{
          backgroundColor: color,
          padding: baloiseStrings ? 0 : '0.5rem',
        }"
      >
        <img
          :src="imageUrl"
          @load="$event.target.classList.toggle('empty-img', false)"
          class="empty-img"
        >
      </div>
      <div
        class="px-3 flex-grow-1 row no-gutters align-items-center"
        :class="{
          'wet-append-chevron-right': !!link,
        }"
      >
        <div class="col mb-2 mb-sm-0">
          <div v-if="single" class="is-size-5">
            {{businessTypeString}}
          </div>
          <div v-if="single" class="text-secondary font-weight-bold">{{title}}</div>
          <div>
            {{description}}
          </div>
        </div>
        <div v-if="single && status === '2'" class="col-12 col-sm-auto">
          <button
            type="button"
            class="wet-button"
            :class="{
              'loading': loading.length > 0
            }"
            :disabled="loading.length > 0"
            @click="$emit('claim')"
          >
            {{ claim_payout_btn }}
          </button>
        </div>
      </div>
    </div>
    <div class="">
      <div class="wet-note">{{locations}}</div>
      <div class="wet-note">{{[startDate, endDate].filter((a) => !!a).join(' / ')}}</div>
      <div class="wet-slash-split">
        <div v-if="showPrices" >
          <div class="is-size-7">
            {{price_including_vat}}
          </div>
          <div class="font-weight-bold">
            <money-field :value="price" size="lg" :currency="currency" />
          </div>
        </div>
        <div v-if="showPayout">
          <div class="is-size-7">
            {{potential_payout}}
          </div>
          <div class="font-weight-bold">
            <money-field :value="payout" size="lg" :currency="currency" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { numToPriceFormat } from '@/common/snippets';
import configEnv from '@/enums/configEnv';

export default {
  components: {
    moneyField: () => import(/* webpackChunkName: "moneyField" */ '@/components/ui/moneyFieldUi.vue'),
    weatherIcon: () => import(/* webpackChunkName: "weatherIcon" */ '@/components/weatherIcon.vue'),
  },
  props: {
    link: [String, Object],
    single: Boolean,
    imageUrl: String,
    color: String,
    businessType: String,
    title: String,
    type: String,
    description: String,
    locations: String,
    startDate: String,
    endDate: String,
    showPayout: Boolean,
    price: String,
    payout: String,
    status: String,
    currency: { type: String, default: '' },
    potential_payout: {
      type: String,
      required: true,
    },
    claim_payout_btn: {
      type: String,
      required: true,
    },
    price_including_vat: {
      type: String,
      required: true,
    },
    weather_type: {
      type: String,
      required: true,
    },
    businessTypeString: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showPrices: configEnv.show_prices,
      baloiseStrings: configEnv.baloise_strings,
      iconTypes: {
        rain: `
<svg viewBox="0 0 24 24">\
  <path d="M17.49 6H18a6 6 0 0 1 2.4 11.5 1 1 0 1 1-.8-1.83A4 4 0 0 0 18 8h-1.25a1 1 0 0 1-1-.75 7 7 0 1 0-11.12 7.22A1 1 0 0 1 3.38 16 9 9 0 1 1 17.49 6zM11 15a1 1 0 1 1 2 0v2a1 1 0 0 1-2 0zm0 6a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0zm4-8a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm0 6a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm-8-6a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0zm0 6a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0z"></path>\
</svg>\
        `,
        temperature: `
<svg viewBox="0 0 24 24">\
  <path d="M14.08 20.62a3.28 3.28 0 0 0 .92-2.57c-.06-.61-1.31-.62-1.25 0a2.59 2.59 0 0 1-.75 2.08c-.47.51.62.99 1.08.49zM12.34 2c1 0 1.27.78 1.27 1.74v11.84a2.87 2.87 0 0 1 2.13 2.62 2 2 0 0 1 0 .27 3.38 3.38 0 0 1-6.76 0 2 2 0 0 1 0-.27 2.92 2.92 0 0 1 .63-1.62 2.87 2.87 0 0 1 1.41-.95V3.79c.05-.96.35-1.79 1.32-1.79zM7.87 15.64A5.26 5.26 0 0 0 7 18.52a5.37 5.37 0 0 0 5.39 5.34 5.44 5.44 0 0 0 1.1-.11 5.33 5.33 0 0 0 1.63-9.84V2.78A2.78 2.78 0 0 0 12.94.07a2.81 2.81 0 0 0-2 .26 2.77 2.77 0 0 0-1.43 2.45V14a5.4 5.4 0 0 0-1.64 1.64zm5.3-8.52V3.79c0-1.3-.58-1.3-.83-1.3s-.83 0-.83 1.3v5.76h1.65V7.11z"></path>\
</svg>\
        `,
        snow: `
<svg viewBox="0 0 24 24">\
  <path d="M9.508.016c3.644.199 6.777 2.53 7.976 5.855h.524c2.836.004 5.285 1.953 5.863 4.672.582 2.723-.863 5.465-3.465 6.582a1.013 1.013 0 0 1-1.316-.504.968.968 0 0 1 .515-1.289c1.73-.746 2.696-2.574 2.31-4.387-.388-1.812-2.017-3.113-3.911-3.117h-1.258a.994.994 0 0 1-.969-.734c-.761-2.883-3.343-4.957-6.382-5.125C6.352 1.8 3.55 3.579 2.46 6.363c-1.086 2.782-.211 5.938 2.168 7.797.434.34.504.953.16 1.375a1.018 1.018 0 0 1-1.406.156C.324 13.297-.805 9.242.593 5.664 1.994 2.082 5.599-.203 9.509.016zm7.004 22.941c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm-4 5.309c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm-4 4.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm0 0"></path>\
</svg>\
        `,
      },
    };
  },
  computed: {
    ...mapState(['lang', 'loading']),
  },
  methods: {
    numToPriceFormat,
  },
};
</script>

<style lang="scss">
  .wet-append-chevron-right, .wet-append-chevron-left {
    position: relative;
  }
  .wet-append-chevron-right {
    padding-right: 1rem;
  }
  .wet-append-chevron-left {
    padding-left: 1rem;
  }
  .wet-append-chevron-right::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    border-top: 2px solid var(--gray-light);
    border-right: 2px solid var(--gray-light);
    width: .5rem;
    height: .5rem;
    transform: translateY(-50%) rotateZ(45deg);
  }
  .wet-append-chevron-left::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 2px solid var(--gray-light);
    border-right: 2px solid var(--gray-light);
    width: .5rem;
    height: .5rem;
    transform: translateY(-50%) rotateZ(-135deg);
  }
  .wet-slash-split {
    display: flex;
  }
  .wet-slash-split>* {
    position: relative;
  }
  .wet-slash-split>*:not(:last-child) {
    padding-right: 1.5rem;
  }
  .wet-slash-split>*:not(:last-child)::after {
    content: "/";
    color: var(--gray-light);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
  }
  .wet-insurance-card {
    display: block;
    background-color: #f9f9f9;
    transition: all .3s;
    border-radius: 0;
    border-width: 1px;
    border-style: solid;
    border-bottom-color: var(--gray-light);
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    position: relative;
    overflow: hidden;

    &.link {
      cursor: pointer;
    }

    &>*:not(.wet-insurance-card-business) {
      position: relative;
    }
  }
  .wet-insurance-card-business {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    border-top-left-radius: 5rem;
    padding: 1.5rem .25rem .25rem 1.5rem;

    &>img {
      width: 100%;
    }
  }
  .wet-insurance-card-business-single {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    border-radius: 2rem;

    &>img {
      width: 100%;
    }
  }
  .wet-insurance-card.link:hover {
    box-shadow: 0 5px .5rem var(--gray-light);
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .wet-insurance-card:not(.single) {
      border-color: var(--gray-light);
      border-radius: .5rem;
    }
  }
</style>
