import common from '@/mixins/common';
import LogService from '@/services/LogService';
import configEnv from '@/enums/configEnv';

const insuranceHelper = {
  mixins: [common],
  data() {
    return {
      wpInsuranceApi: '/wp-json/acf/v3/options/insurance',
    };
  },
  computed: {
    businessTypes() {
      LogService.log('businessTypes start');
      let types = {};
      if (this.str.insurance) {
        const entries = this.str.insurance.business_type.map((i) => [
          i.type,
          {
            url: i.image.url,
            color: i.color,
          },
        ]);
        types = Object.fromEntries(entries);
      }
      LogService.log('businessTypes', types);
      return types;
    },
  },
  methods: {
    mapApiData(item) {
      /** map api data to insurance-id props */
      const wpStatus = this.getStatus(item);
      const startDate = item.dates
        && this.$moment.min(item.dates.map((date) => this.$moment(date)));
      const endDate = item.dates && this.$moment.max(item.dates.map((date) => this.$moment(date)));
      let diff = 0;
      switch (wpStatus.id) {
      case 'future':
      case 'draft':
        diff = Math.abs(this.$moment.today().diff(startDate, 'days'));
        break;
      case 'active':
        diff = Math.abs(this.$moment.today().diff(endDate, 'days'));
        break;
      case 'finished':
      case 'claimed':
        diff = endDate ? Math.abs(endDate.diff(this.$moment.today(), 'days')) : 0;
        break;
      default:
        break;
      }
      const type = item.weather?.type;
      let value;
      if (!configEnv.baloise_strings) {
        value = this.str.commonApp.weather_type ? this.str.commonApp.weather_type[type] : '';
      } else {
        value = this.str.insurance.starts_in ? this.str.insurance.starts_in : '';
      }

      let description = value;

      if (!['claimed'].includes(wpStatus.id)) {
        if (!configEnv.baloise_strings) {
          description = `${description} -`;
        }
        description = `${description} ${wpStatus.action} `;
      }

      description = this.format(description, diff, `${diff === 1 ? this.str.commonApp?.day : this.str.commonApp?.days_dative}`);
      return {
        id: item.id,
        title: wpStatus.title,
        type,
        statusType: wpStatus.id,
        status: item.status?.toString(),
        description,
        locations: item.locations,
        startDate: startDate && startDate.format('DD MMM YYYY'),
        endDate: endDate && endDate.format('DD MMM YYYY'),
        price: item.price || '',
        payout: item.payout || '',
        businessType: item.business?.type,
        currency: item.currency,
      };
    },
    getStatus(item) {
      const wpStatuses = this.str.insurance?.statuses?.map((s) => ({
        ...s,
        codes: s.code.split(',').map((c) => (c || '').trim()),
      })) || [];
      const id = wpStatuses.findIndex((s) => s.codes.includes(`${item.status}`));
      const wpStatus = wpStatuses[id] || {};
      wpStatus.order = id + 1;

      return id > -1 ? wpStatus : {};
    },
  },
};

export default insuranceHelper;
